<template>
  <div>
    <v-card-text>
      <v-form class="mt-6" ref="form" lazy-validation v-model="valid">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field color="#137547" type="text" required v-model.trim="userInfo.firstName" label="Ime" :rules="nameRules" dense outlined></v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field color="#137547" type="text" required v-model.trim="userInfo.lastName" label="Prezime" :rules="lastNameRules" dense outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              color="#137547"
              :error="usernameError"
              required
              v-model.trim="userInfo.userName"
              label="Korisničko ime"
              type="text"
              :rules="userNameRules"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <div v-if="usernameError" style="margin-top: -1.5rem">
            <p style="text-align: center; color: red">Korisnicko ime je vec zauzeto</p>
          </div>
          <v-col cols="12" md="6">
            <v-text-field color="#137547" :error="emailError" type="email" required v-model.trim="userInfo.email" dense label="E-mail" :rules="emailRules" outlined></v-text-field>
          </v-col>
          <div v-if="emailError" style="margin-top: -1.5rem">
            <p style="text-align: center; color: red">Korisnik sa ovim emailom vec postoji</p>
          </div>
          <v-col cols="12" md="6">
            <v-text-field color="#137547" type="number" required v-model.trim="userInfo.phoneNumber" :rules="phoneNumberRules" dense label="Broj telefona" outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field color="#137547" type="text" required v-model.trim="userInfo.location" :rules="locationRules" dense label="Lokacija" outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn color="success" class="me-3 mt-4" @click="updateUser"> Sačuvaj </v-btn>
            <v-btn color="secondary" outlined class="mt-4" type="reset" @click.prevent="resetForm"> Poništi </v-btn>
          </v-col>
          <v-snackbar v-model="snackbar" color="green" bottom> Uspešno ste promenili informacije. Molimo Vas da se opet prijavite. </v-snackbar>
        </v-row>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      allUsers: [],
      valid: true,
      usernameError: false,
      emailError: false,
      nameRules: [(v) => !!v || "Ime je obavezno.", (v) => (v && v.length <= 15) || "Ime ne može da ima više od 15 karaktera."],
      lastNameRules: [(v) => !!v || "Prezime je obavezno.", (v) => (v && v.length <= 15) || "Prezime ne može da ima više od 15 karaktera."],
      phoneNumberRules: [(v) => !!v || "Broj telefona je obavezan."],
      locationRules: [(v) => !!v || "Lokacija je obavezna.", (v) => (v && v.length <= 40) || "Lokacija ne može da ima više od 40 karaktera."],
      userNameRules: [(v) => !!v || "Korisničko ime je obavezno.", (v) => (v && v.length <= 15) || "Korisničko ime ne može da ima više od 15 karaktera."],
      emailRules: [
        (v) => !!v || "Email je obavezan.",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "Unesite email u ispravnom formatu.",
      ],
      snackbar: false,
      userInfo: {
        ...this.$store.getters.StateCurrentUser,
      },
      initialValues: {
        ...this.$store.getters.StateCurrentUser,
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    // currentLoggedUser() {
    //   return this.$store.getters.StateCurrentUser;
    // },
  },
  methods: {
    ...mapActions(["LogOut"]),
    async logOut() {
      await this.LogOut();
      this.$router.push("/pocetna");
    },
    async updateUser() {
      if (this.$refs.form.validate()) {
        let updatingUser = {
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          userName: this.userInfo.userName,
          email: this.userInfo.email,
          phoneNumber: this.userInfo.phoneNumber,
          location: this.userInfo.location,
        };
        await axios
          .put(`User/${this.userInfo.id}`, updatingUser)
          .then(() => {
            this.snackbar = true;

            setTimeout(() => {
              this.snackbar = false;
              this.logOut();
            }, 2000);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data == "Email is already taken.") {
              this.emailError = true;
            } else if (error.response.data == "Username is already taken.") {
              this.usernameError = true;
            }
          });
      }
    },
    resetForm() {
      this.userInfo = { ...this.initialValues };
    },
  },
};
</script>

<style lang="scss" scoped></style>
